@tailwind base;
@tailwind components;
@tailwind utilities;
*{
  margin: 0;padding: 0;box-sizing: border-box;
  font-family: "Lexend", sans-serif;
}
.textshadow{
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.textshadow2{
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
.HMbg{
  background: url('./Assets/bgImages/WWRBG2.jpeg');               
}

.carousel-card {
  /* Adjust these values as needed */
  width: 100%;  /* or a specific width */
  height: 300px; /* adjust height according to your needs */
  transition: transform 0.3s ease-in-out;
}

.carousel-card img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This helps with irregular shapes */
}

.topnav{
  height: 50px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 30px;
}
.topnav button{
  padding: 5px 15px;
  background: none;
  color: white;
  border: 1px solid white;
}
nav{
  position: fixed;
width: 100%;
top: 0;
z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 40px;
}
nav img{
  height: 60px;
}
.newlogo{
  height: 100px;

}
nav ul{
  display: flex;
  flex-direction: row;
  align-items: center !important;
 gap: 25px;
 margin-bottom: 0;
}
nav ul li{
  list-style: none;
}
.Navbar{
  width: 100%;
  z-index: 10;

}
.banner{
  background-image: url(./Assets/153392.jpg);
  background-position: bottom;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
}
.overlay{
  padding-top: 200px;
padding-bottom: 200px;
  /* background-color: rgba(0, 0, 0, 0.823); */
  height: 100%;
  width: 100%;
  text-align: center;

}
.overlayStyle{
  padding-top: 200px;
padding-bottom: 200px;
  background-color: rgba(0, 0, 0, 0.750);
  height: 100%;
  width: 100%;
  color: whitesmoke;
  text-align: center;

}
.banner h1{
  font-size: 55px;
  font-weight: 700;
  text-align: center;
}
.banner h1 span{
  font-size: 70px;

}
.banner p{
  font-size: 25px;
  font-weight: 400;
  margin-top: 10px;
}
.banner-p{
  padding: 50px 200px;
  text-align: center;
  
}
.banner-p p{
  font-size: 13.0pt;
  font-weight: 400;
}
.ourecore{
  background-image: url(./Assets/Demo\ theme\ \(27\).png);
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 100px;

}
.ourecore h3{
  font-size: 13.0pt;
  font-weight: 700;
}
.ourecore p{
  font-size: 13.0pt;
  font-weight: 400;
}
.heading{
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;

}
.heading h1{
  font-size: 24.0pt;
font-weight: 700;
}
.activity{
background-color: #000000a5;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: white;
  gap: 100px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.newactivity{
  background-image: url(./Assets/istockphoto-1404373434-170667a.webp);
  background-position: center;
  background-size: cover;

}
.activity ul{
  width: 30%;
}
.activity ul li{
  list-style: none;
  margin-top: 20px;
  font-size: 13.0pt;
}
.activity ul li p{
  /* text-align: center; */
  font-weight: 800;
  font-size: 15.0pt;

}
.partners{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding-left: 50px;padding-right: 50px;
}
.partners img{
  width: 30%;
}
.SSD h1{
  text-align: center;

  font-size: 24.0pt;
  font-weight: 700;
}
.SSD{
  padding-top: 50px;
  padding-bottom: 50px;
}
.Journey{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Journey img{
  width: 80%;
}
.Journey h1{
  text-align: center;

  font-size: 24.0pt;
  font-weight: 700;
}

.founderdiv{
 background-image: url(./Assets/istockphoto-1404373434-170667a.webp);
 background-position: center;
 background-size: cover;

}
.founder{
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
 background-color: #000000bb;
}
.founder-left{
  width: 70%;
  color: white;
  text-align: center;
  padding-left: 100px;
  padding-right: 100px;
}
.founder-left h2{
  font-size: 24.0pt;
  font-weight: 700;
}
.founder-left p{
  font-size: 12.0pt;
  font-weight: 700;
}
.founder-right{
  text-align: center;
  width: 30%;
  color: white;
}
.founder-right h3{
  font-size: 14pt;
  font-weight: 700;

}
.founder-right p{
  font-size: 11.0pt;
  font-weight: 400;
}
.founder-right img{
  height: 200px;
}
.Events{
  padding-top: 50px;
  padding-bottom: 50px;
}
.Events h1{
  text-align: center;
}
.footer{
  background-color: black;
display: flex;
flex-direction: row;
justify-content: space-around;
/* align-items: center; */
  color: white;
  padding-top: 50px;
  padding-bottom: 20px;
  gap: 50px;
  flex-wrap: wrap;
  padding-left: 30px;
  padding-right: 30px;
}
.footer ul li{
  list-style: none;
  margin-bottom: 20px;
}
.footer ul li button{
  width: 200px;
  height: 40px;
  background-color: white;
  border-radius: 10px;

}
.bottom-foot{
  height: 40px;
  background-color: #000000;
}
.bottom-foot p{
  text-align: center;
  color: white;
  font-size: 15px;
  font-weight: 400;
}
.flink{
  text-decoration: none;
  color: white !important;
  font-weight: 500;
}
.footer ul li{
  display: flex;
  gap: 10px;
}
.footer ul li i{
  font-size: 20px;
}
/* Nav */
.footer-ul{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bi-list{
  color: rgb(0, 0, 0);
  font-size: 25px !important;
  display: none;
}
@media(max-width:1200px){
  nav ul{
    display: none;
    }
    .bi-list{
      display: block;
    }
    .topnav{
      display: none;
    }
    .banner-p{
      padding: 50px 50px;
      text-align: center;
      
    }
    nav{
  padding: 15px 20px;

    }

}
.bi-list {
  cursor: pointer;
}

/* Styles for the toggleable div */
.toggleDiv {
  width: 100%;
  height: 100%;
  margin-top: 10px;
  padding: 20px;
  background-color: #000000d4;
  border: 1px solid #dddddd;
  border-radius: 50px;
  color: white;
  text-align: center;
margin-top: 35px;
padding-top: 25px;

}
.toggleDiv ul li{
  font-family: "Manrope", sans-serif;
  color: white;
  font-weight: 600;
  font-size: 20px;
  list-style: none;
  margin-bottom: 20px;
}
.toggleDiv-ops{
  width: 100%;
  height: 100vh;
  background-color: #000000c1;
  position: fixed;
z-index: 100;
  top: 80px;
}
@media(max-width:550px){
  .banner-p{
    padding: 20px 20px;
    text-align: center;
    
  }
.partners{
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.partners img{
  width:60%;
}
}
@media(max-width:670px){
  .ourecore{
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  gap: 50px;

  }
  .activity{
  gap: 50px;
  padding-left: 20px;
  padding-right: 20px;

  }
}

@media(max-width:850px){
  .founder{
flex-direction: column;
gap: 50px;

  }
.founder-left{
  width: 100%;
  padding-left: 50px;
padding-right: 50px;
}
.founder-right{
  width: 100%;

}
}

@media(max-width:550px){
  .founder-left{
    width: 100%;
    padding-left: 20px;
  padding-right: 20px;
  }

}
.about-heading{
  height: 200px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;gap: 15px;
}
.about-heading h1{
  font-size: 34.0pt;
  font-weight: 500;
}
.text{
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  gap: 30px;
  padding: 50px 50px;
}
@media(max-width:900px){
.text{
flex-direction: column;
}
}
.project-div{

  padding: 50px 150px;

}
.project-div h2{
  font-size: 32px;
  font-weight: 700;
}
.project-div p{
  font-size: 19px;
  font-weight: 400;
}
.project-div-con{
  display: flex;
  flex-direction: row;
}
.project-div-con-left{
  width: 60%;
}
.project-div-con-right{
  width: 40%;

}
.project-div2{
  color: white;
  background-position: center;
  background-size: cover;
  background-image: url(./Assets/9Jp6xxqEmsDr2KRMPZii0Q8jFKvDdodeDzAbjqsHxqBUQsM42tUfcKIHfJ1YAn22F_3SSdToCkQ5WfsnuK9CH34=w16383.png);
}
.Services-points{
  background-color: rgb(223, 223, 223);
  padding: 50px 150px;
}
.Services-points h2{
  color: #351c75;
  font-size: 24px;
  font-weight: 700;
}
.Services-points p{
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}

@media(max-width:768px){
  .Services-points{
    padding: 50px 30px;
  }
  .project-div{
    padding: 50px 30px;

  }
}
.mission{
  padding: 50px 200px;
}
.mission h2{
  font-size: 30px;
  font-weight: 400;
}
.WhatWedo{
  display: flex;
  flex-direction: column;
  background-color: black;
  color: white;
}
.WhatWedo h1{
  text-align: center;
}
.none{
  color: black;
  text-decoration: none;
}
.none2{
  color: rgb(255, 255, 255) !important;
  text-decoration: none;
}
.team-main-left-standard{
  width: 50%;
  display: flex;
  justify-content: left;
  align-items: left;
}
.team-main-left-standard img{
  width: 90%;
  height: 80%;
  margin-top: 20% ;
}
.team-main{
  padding: 50px;
  display: flex;
  flex-direction: row;
}
.team-main-left{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.team-main-left img{
  width: 40%;
}
.team-main-right{
  width: 50%;
  padding: 30px;
}
.all-teams{
  background-color: #F5F5F5;
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: space-evenly;
}
.team-card{
  width: 20%;
  position: relative;
  z-index: 0;
}
.team-card img{
  width: 100%;
  z-index: 0;
}
.team-text{
  position: absolute;
  height: 50px;
  width: 80%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  right: 0;
  margin-top: -50px;
}
.team-text p{
  font-size: 15px;
  font-weight: 500;
}
@media(max-width:768px){
  .banner h1{
    font-size: 40px;
    font-weight: 700;
    text-align: center;
  }
  .overlay{
    padding-top: 300px;
  padding-bottom: 300px;
}
.activity{
  gap: 50px;

}
}

@media(max-width:550px){
  .banner{
   
  }
  .banner h1{
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }
  .overlay{
    padding-top: 150px;
  padding-bottom: 90px;
  padding-left: 30px;
  padding-right: 30px;
}
.banner-p{
  padding-left: 20px;
  padding-right: 20px;
}
}
@media(max-width:700px){
  .activity ul{
    width: 90%;
  }
}
@media(max-width:1200px){
.team-main-left img{
  width: 80%;

}
}
@media(max-width:800px){
.team-main{
  flex-direction: column;
}
.team-main-left , .team-main-right{
  width: 100%;
}
.team-main-left img{
  width: 300px;

}
.team-main-right{
  padding: 0px;
  padding-top: 30px;
}
}
@media(max-width:600px){
  .team-main{
    padding: 30px;
  }
}
@media(max-width:1000px){
  .team-card{
    width: 30%;
  }
  .all-teams{
    gap: 30px;
  }
}
@media(max-width:768px){
.team-card{
  width: 40%;
}
.all-teams{
  padding: 15px;
}
.mission{
  
  padding: 50px 20px;

}
}
@media(max-width:600px){
  .team-card{
    width: 45%;
  }
}
.motivation{
  display: flex;
  flex-direction: row;
  /* padding-top: 50px; */
}
.motivation-left{
  width: 50%;
}
.motivation-right{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;

}
.motivation-right img{
  width: 90%;
}
.motivation-left{
  padding: 50px;
}
.motivation-left h2{
  font-size: 30px;
  text-align: center;
  color: #536B16;
}.banner2{
  background-image: url(./Assets/90537.jpg);
}
.motivation-left2{
  display: flex;
  justify-content: center;
  align-items: center;
}
.motivation-left2 h3{
  font-weight: 300;
  font-size: 25px;
}
.headingcenter{
  font-size: 30px;
  text-align: center;
  color: #536B16;

}
.Expertise-cards{
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  gap: 50px;
  flex-wrap: wrap;
}
.Expertise-card{
 width: 25%; 
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 gap: 15px;
 text-align: center;
 padding-bottom: 20px;
 box-shadow: 4px 4px 31px -21px rgba(0,0,0,0.75);
}
.Expertise-card img{
  width: 100%;
 border-radius: 20px 20px 0 0;
 height: 250px;

}
.Expertise-card h2{
 padding: 20px;
  
}
@media(max-width:900px){
.motivation{
  flex-direction: column-reverse;
}
.motivation-left{
  width: 100%;
}
.motivation-right{
  width: 100%;
}
}
@media(max-width:550px){
  .motivation-right{
    padding: 20px;
  }
  .motivation-right img{
    width: 100%;
  }
  .motivation-left{
    padding: 20px;
  }
  .motivation-left h2{
    font-size: 20px;
  }
  .motivation-left2 h3{
    font-weight: 300;
    font-size: 18px;
  }
  .headingcenter{
    font-size: 15px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media(max-width:1100px){
  .Expertise-cards{
    padding-left: 20px;padding-right: 20px;
  }
  .Expertise-card{
    width: 30%; 
  }
}
@media(max-width:900px){
  .Expertise-card{
    width: 40%; 
  }
}
@media(max-width:768px){
  .Expertise-card{
    width:45%; 
  }
}
@media(max-width:550px){
  .Expertise-card{
    width:100%; 
  }
  .Expertise-card img{
    height: auto;
  }
}

.banner3{
  background-position: center;


  background-image: url(./Assets/patentbgimg.png);
}
.list-patents{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
padding: 50px;
gap: 50px;
}
.list-patent{
  width: 80%;
background-color: rgb(255, 251, 251);
box-shadow: 4px 4px 31px -21px rgba(0,0,0,0.75);
padding: 15px 50px;
border-radius: 20px;
display: flex;
justify-content: start;
/* align-items: center; */
flex-direction: column;
gap: 30px;
}
.acc-list{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}
.no{
  background-color: #7E89FF;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  border-radius: 50%;
}
.no h3{
  font-size: 25px;
  font-weight: 700;
}
.list-patent h2{
  font-size:15px;
  font-weight: 500;
}
.list-patents h4{
  font-size:35px;
  font-weight: 600;
}
.banner4{
  background-image: url(./Assets/reviewedarticles.jpeg);
  background-position: center;
  display: flex;
}
.list-patents h5{
  font-size: 20px;
}
@media(max-width: 768px){
  .list-patent{
    flex-direction: column;
    text-align: center;
  }
}
@media(max-width: 550px){
  .list-patent{
  padding: 20px 20px;
  width: 90%;
}
.list-patents{
  padding: 20px;
}
.banner p{
  font-size: 12px;
}
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.banner5{
  background-position: center;
  background-image: url(./Assets/posterfinal.png);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.banner6{
  background-position: center;
  background-image: url(./Assets/conf3.png);
  display: flex;
}
.banner-books{
  background-position: center;
  background-image: url(./Assets/Banner_book_chapters.png); /* Change the background image */
}

.list-books{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  gap: 50px;
}

.list-book{
  width: 80%;
  background-color: rgb(255, 251, 251);
  box-shadow: 4px 4px 31px -21px rgba(0,0,0,0.75);
  padding: 15px 50px;
  border-radius: 20px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 30px;
}

.acc-list-books{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.no-books{
  background-color: #7E89FF;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.list-book h2{
  font-size: 15px;
  font-weight: 500;
}

.list-books h4{
  font-size: 25px;
  font-weight: 600;
}

.list-books h5{
  font-size: 20px;
}
.banner-talks{
  background-position: center;
  background-image: url(./Assets/talkwhite.png); /* Change the background image */
}

.list-talks{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  gap: 50px;
}

.list-talk{
  width: 80%;
  background-color: rgb(255, 251, 251);
  box-shadow: 4px 4px 31px -21px rgba(0,0,0,0.75);
  padding: 15px 50px;
  border-radius: 20px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 30px;
}

.acc-list-talks{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.no-talks{
  background-color: #7E89FF;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.list-talk h2{
  font-size: 15px;
  font-weight: 500;
}

.list-talks h4{
  font-size: 25px;
  font-weight: 600;
}

.list-talks h5{
  font-size: 20px;
}
.contactus{
  display: flex;
  flex-direction: row;

}
.contactus-left{
  width: 50%;
  padding: 50px;
padding-right: 100px;
  padding-left: 100px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  gap: 50px;
}
.cont-adss{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
}
.cont-adss p{
  font-size: 20px;
}
.contactus-right{
  width: 50%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  padding: 50px;
  padding-left: 100px;
  padding-right: 100px;

}
.contactus-right label{
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 15px;
  margin-top: 20px;
}
.contactus-right input{
  height: 40px;
  padding-left: 15px;
  border-radius: 10px;
  border: 1px solid gray;
}
.contactus-right button{
  height: 40px;
  border-radius: 10px;

  border: none;
  background-color: #000000;
  color: white;

}
.cont-icon{
  height: 60px;
  width: 60px;
  background-color: #000000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 20px;
}
.map-div{
  height: 300px;
  width: 100%;
}
.map{
  width: 100%;
  height: 100%;
}
@media(max-width:1000px){
  .contactus-left , .contactus-right{
    padding-right: 50px;
  padding-left: 50px;
  }
}
@media(max-width:768px){
  .contactus{
    flex-direction: column;
  }
  .contactus-left , .contactus-right{
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
  
}
@media(max-width:768px){
.cont-adss{
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

}
}
.newtable{
  overflow-x: auto !important;
}
.toggleDiv ul{
  padding-left: 0;
}
.toggleDiv ul li{
  margin-bottom: 12px;
}
ul{
  padding-left: 0 !important;

}
p{
  margin-bottom: 0 !important;
}
.text-bar{
  width: 30%;
}
@media(max-width:600px){
  .text-bar{
    width: 100%;
  }
}
.aboutus-section{
  display: flex;
  flex-direction: row;
  /* padding: 20px; */
}
.aboutus-right{
  padding: 30px;
  width: 60%;

}
.aboutus-left{
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutus-left img{
  width: 100%;
}
.banner11{
  background-image: url(./Assets/5360.jpg);
  background-position: bottom;
}
.banner22{
  background-image: url(./Assets/Our-Expertise.jpg);
  background-position: top;
}
.container h2{
  text-align: center;
}
@media(max-width:1000px){
.aboutus-section{
  flex-direction: column;
}
.aboutus-left{
  width: 100%;
}
.aboutus-right{
  width: 100%;
}
}
.modal-left{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-right{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}
.modal-left img{
  width: 80%;
}
.modal-div{
  display: flex;
  flex-direction: row;

}
@media(max-width:980px){
  .modal-div{
    display: flex;
    flex-direction: column;
  
  }
  .modal-left{
    width: 100%;
  }
  .modal-right{
    width: 100%;
    margin-top: 20px;
  }
  .modal-left img{
    width: 50%;
  }
}
.team-name{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
 gap: 10px;
}
.team-name p{
  font-size: 15px;
}
.team-name button{
  border: none;
  background-color: #000000;
  color: white;
  border-radius: 10px;
  padding: 8px;
  font-size: 15px;
}
.arrow{
  height: 30px;
  width: 30px !important;
}
form{
  display: flex;
  flex-direction: column;
}
.error{
  color: red;
}
